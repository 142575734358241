<template>
    <div class="order-actions mobile xl-hide">
        <button :disabled="no_click"  v-if="order.status != 'listed'" class="button small-button box-button" @click="preLoadOrder()">Load Order</button>
        <span v-if="order.status != 'draft'">
            <router-link v-if="order.po == '1'" class="button small-button box-button" :to="{name:'orderSubmittedPo', params: { orderNumber: order.slug}}" >View Order</router-link>
            <router-link v-else class="button small-button box-button" :to="{name:'orderSubmitted', params: { orderNumber: order.slug}}" >View Order</router-link>
        </span>
        <button :disabled="no_click" v-if="order.status != 'listed'" class="button small-button box-button" @click="preDeleteOrder()">Delete Order</button>
    </div>
    <div class="order-actions xs-hide xl-show" ref="actions">
        <details class="table-dropdown-menu" :open="open">
           <summary class="table-dropdown-icon-button" @click.prevent="toggle">
                <span class="icon-text visuallyhidden">order actions</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                </svg>
           </summary>
           <nav class="table-dropdown-menu-nav box">
                <ul>
                    <li class="line" v-if="order.status != 'listed'">
                        <button :disbaled="no_click" class="text-button" @click="preLoadOrder()">Load Order</button>
                    </li>
                    <li class="line" v-if="order.status != 'draft'" >
                        <router-link v-if="order.po == '1'" :to="{name:'orderSubmittedPo', params: { orderNumber: order.slug}}" >View Order</router-link>
                        <router-link v-else :to="{name:'orderSubmitted', params: { orderNumber: order.slug}}" >View Order</router-link>
                    </li>
                    <li class="line" v-if="order.status != 'listed'">
                        <button :disbaled="no_click" class="text-button" @click="preDeleteOrder()">Delete Order</button>
                    </li>
                </ul>
            </nav>
        </details>        
    </div>  

    <transition name="modal">
        <div v-if="load" class="modal-mask page-margins" @click="clickHandler">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-text">Loading an order will clear your current cart, do you wish to continue?</div>
                    <div v-if="in_progress" class="modal-actions">
                        <button class="button box-button disabled" disabled>Loading Order</button>
                    </div>
                    <div v-else class="modal-actions">
                        <button class="button box-button" @click="loadOrder(order.slug, order.po)">Yes</button> 
                        <button class="button box-button" @click="load = false">No</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="modal">
        <div v-if="delete_order" class="modal-mask page-margins" @click="clickHandler">
            <div class="modal-wrapper">            
                <div class="modal-container">
                    <div class="modal-text">Are you sure you wish to delete this order?</div>
                    <div v-if="in_progress" class="modal-actions">
                        <button class="button box-button disabled" disabled>Deleting Order</button>
                    </div>
                    <div v-else class="modal-actions">
                        <button class="button box-button" @click="deleteOrder(order.slug)">Yes</button> 
                        <button class="button box-button" @click="delete_order = false">No</button>
                    </div>
 
                </div>
            </div>
        </div>
    </transition>
    <transition>
        <div v-if="in_progress" class="load-container">
            <div class="load-container-inner">
                <LoadingElement></LoadingElement>
                <span class="visuallyhidden">Loading...</span>
            </div>
        </div>  
    </transition>
</template>

<script>
import LoadingElement from  '@/components/loader.vue'
export default {
    name: 'OrderActions',
    props: ['order'],
    emits: ['delete'],
    data() {
        return {
            open: false,
            no_click: false,
            menuOpen: false,
            load: false,
            delete_order: false,
            in_progress: false
        }
    },
    components:{
        LoadingElement
    },
    created() {
      window.addEventListener("click", this.close);
    },
  
    beforeUnmount() {
      window.removeEventListener("click", this.close);
    },
  
    methods: {
        async loadOrder(orderNumber, isPO){
            this.no_click = true;
            this.in_progress = true;
            await this.$store.dispatch('orders/load_order', orderNumber);
            if(isPO){
                this.$router.push({name: 'po-order'});
            }else{
                this.$router.push({name: 'order'});
            }
            
        },
        preLoadOrder(){
            this.load = true;
            this.delete_order = false;
            this.open = false;
        },
        async deleteOrder(orderNumber){
            this.no_click = true;
            this.in_progress = true;
            await this.$emit('delete', orderNumber)
            this.no_click = false;
            this.in_progress = false;
        },
        preDeleteOrder(){
            this.delete_order = true;
            this.load = false;
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
            this.delete_order = false;
            this.load = false;
        },
        closeAll(){
            this.open = this.delete_order = this.load = false;
        },
        clickHandler(event){
            if(!event.target.closest(".modal-container")) {
                this.closeAll();
            }
        },
        close(e) {
            if (!this.$refs.actions.contains(e.target) && this.open ){
                this.open = false;
                this.delete_order = false;
                this.load = false;
            }
        }
    },

}
    
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.load-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0,0,0,.25);
    z-index: 99999;
}

.load-container-inner{
    margin: 0 auto;
}





.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  max-height: 100%;
  // height: 100%;
}

.modal-container {
  width: 100%;
  max-width: 400px;
  max-height: 100%;
  overflow: auto;
  margin: 0px auto;
  background-color: #fff;
  border: 1px solid black;
  transition: all 0.3s ease;
  padding: 20px;
}

.modal-text{
    font-size: 1.25em;
    line-height: 1.3;
}

.modal-actions{
    padding-top: 3em;
    button{
        padding: 10px 30px;
        margin-right: 1em;
    }
}


@media only screen and (max-width: nth($xl, 2)) {
    .order-actions{
        padding: 30px 0 0;
        button, a{
            display: inline-block;
            margin-right: 1em
        }
    }
}

 @media only screen and (min-width: nth($xl, 2)) {
    .order-actions{
        text-align: right;
    }
}  
</style>