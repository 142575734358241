<template>
    <footer class="footer">
        <div class="page-grid">
            <div class="footer-text xs-col-12 lg-col-3" v-html="footertext"></div>
            <nav class="footer-nav xs-col-12 lg-col-9">
                <ul class="footer-menu">
                    <template
                        v-for="item in menu_items" 
                        :key="item.title"
                        >
                        <li class="main-menu-item">
                            <router-link :to="{name:'helpchild', params: {pageSlug: item.slug}}">
                            {{item.title}}
                            </router-link>
                        </li>
                </template>
                </ul>
            </nav>
        </div>
    </footer>
</template>
<script>
    export default {
        name: 'NavFooter',
        data () {
            return {
                menu_items: null,
                footertext: null
            }
        },
        async mounted () {
            let api = await this.$axios.get("/site", {'headers': {'X-CSRF': this.$store.state.auth.csrf}});
            console.log(api.data.data.content);
            let content = api.data.data.content;
            let menu = []
            for(let i=0;i<content.footermenu.length;i++){
                let menu_item = content.footermenu[i];
                if (menu_item.mainmenu[0]){
                    let slug = menu_item.mainmenu[0].id.replace('help/','')
                    let title = menu_item.title;
                    menu.push({'slug':slug, 'title': title})
                }
                
                console.log(menu_item.mainmenu)
            }
            this.menu_items = menu;
            this.footertext = content.footertext
            console.log(this.menu_items);
        }

    }

</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables";
    .footer{
        padding-top: var(--pageMargins);
        padding-bottom: var(--pageRowGap);
    }
    .footer-nav{
        margin-top: var(--pageRowGap);
        border-top: 1px solid var(--lightBorder);
    }
    li{
        height: 32px;
        line-height: 34px;
        border-bottom: 1px solid var(--lightBorder);
        a{
            display: block;
        }
    }

    @media only screen and (min-width: nth($lg, 2)) {
        .footer{
            font-family: "FranklinGothicCondensed", 'arial', sans-serif;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 14px;
        }

        .footer-nav{
            margin: 0;
            border: 0;
            text-align: right;
        }

        li{
            height: auto;
            line-height: inherit;
            display: inline-block;
            padding-left: var(--pageColumnGap);
            border: 0;
            a{
                padding: 0;
                display: block;
            }
        }
    }
    @media only screen and (min-width: nth($xl, 2)) {
        .footer-nav{
            text-align: left;
        }
        li{
            padding-left:0 ;
            padding-right: var(--pageColumnGap);
        }
    }

    @media print{
      .footer{
        display: none;
      }
    }


</style>