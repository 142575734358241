<template>
    <details class="mobile-icon-menu-submenu" :open="open">
      <summary class="mobile-menu-icon-link" @click.prevent="toggle">
        <span class="icon-text visuallyhidden">account</span>
        <img src="../../assets/img/profile-icon-3.svg"/>
      </summary>
      <nav class="mobile-icon-menu-submenu-nav box">
        <ul>
          <li>
            <router-link class="line" to="/account/orders">Orders</router-link>
          </li>
          <li>
            <router-link class="line" to="/account/account-details">Account Details</router-link>
          </li>
          <li>
            <router-link class="line" to="/account/addresses">Addresses</router-link>
          </li>
          <li>
            <a class="line" href="#" @click="logout">Sign Out </a>
          </li>
        </ul>
      </nav>
    </details>
  </template>
  
  <script>
  export default {
    data() {
      return {
        open: false
      };
    },
  
    created() {
      window.addEventListener("click", this.close);
    },
  
    beforeUnmount() {
      window.removeEventListener("click", this.close);
    },
  
    methods: {
      toggle() {
        this.open = !this.open;
      },
      logout() {
            this.$store.dispatch("logout");
        },
      close(e) {
        if (!this.$el.contains(e.target)) {
          this.open = false;
        }
      }
    },	
    watch: {
      $route() {
        this.open = false;
      }
    }
  };
  </script>
  