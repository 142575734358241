<template>
    <div class="user-actions mobile xl-hide">
        <button class="button small-button box-button" @click="toggleModal('password')">Change Password </button> &nbsp;
        <button class="button small-button box-button" @click="toggleModal('invite')">Email Invite</button>
    </div>
    <div class="user-actions xs-hide xl-show" ref="actions">
        <details class="table-dropdown-menu" :open="menuOpen">
            <summary class="table-dropdown-icon-button" @click.prevent="toggleMenu">
                <span class="icon-text visuallyhidden">user actions</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-three-dots" viewBox="0 0 16 16">
                    <path
                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                </svg>
            </summary>
            <nav class="table-dropdown-menu-nav box">
                <ul>
                    <li class="line">
                        <button class="text-button" @click="toggleModal('password')">Change Password </button>
                    </li>
                    <li class="line">
                        <button class="text-button" @click="toggleModal('invite')">Email Invite</button>
                    </li>
                </ul>
            </nav>
        </details>
    </div>

    <transition name="modal">
        <div v-if="modalVisible" class="modal-mask page-margins" @click="clickHandler">
            <div class="modal-wrapper user-actions-modal">
                <div class="modal-container">
                    <div class="modal-header">
                        <h3 v-if="modalMode == 'password'">Change Password for: {{ user.name }}</h3>
                        <h3 v-if="modalMode == 'invite'">Email</h3>
                    </div>
                    <div class="modal-body">
                        <!-- Change Password -->
                        <form v-if="modalMode == 'password'" @submit.prevent="validatePassword">
                            <div class="form-input">
                                <label class="input-label subhead caps" for="new-password">New Password <span class="password-warning">{{ passwordError }}</span></label>
                                <input class="new-password box-input" v-if="!viewPassword" type="password" name="new-password"
                                    autocomplete="new-password" v-model="newPassword" :disabled="passwordChanged"
                                    @input="validatePassword()">
                                <input class="new-password box-input" v-else type="text" name="new-password"
                                    autocomplete="new-password" v-model="newPassword" :disabled="passwordChanged"
                                    @change="validatePassword()">
                                <button class="toggle-password" @click.stop="toggleViewPassword()">
                                    <span v-if="viewPassword">Hide Password</span>
                                    <span v-else>Show Password</span>
                                </button>
                            </div>
                            <div class="form-input">
                                <label class="input-label subhead caps" for="re-enter-password">Re-Enter Password <span v-if="newPassword2 && !passwordsMatch" class="password-warning">Passwords do not match</span></label>
                                <input class="new-password box-input" v-if="!viewPassword" :disabled="!passwordValid" type="password" name="re-enter-password"
                                v-model="newPassword2" autocomplete="new-password">
                                <input class="new-password box-input" v-else type="text" name="re-enter-password"
                                    autocomplete="new-password" v-model="newPassword2" :disabled="!passwordValid"
                                    @change="validatePassword()">
                            </div>
                            <div class="form-action-buttons">
                                <button v-if="!passwordChanged" class="button big-button order-continue-button"
                                    :disabled="!passwordsMatch" @click.prevent="changePassword()">
                                    Reset Password
                                </button>
                                <button v-if="passwordChanged" class="button big-button order-continue-button"
                                    :disabled="true">Password Changed</button>
                                <div v-if="changeError" class="password-warning">
                                    <span>{{ changeError }}</span>
                                </div>
                            </div>
                        </form>
                        <!-- Email invite -->
                        <div v-if="modalMode == 'invite'">
                            <p class="modal-text">Do you wish to resend an invite to: <b>{{ user.email }}</b></p>
                            <div class="modal-actions">
                                <button class="button box-button" @click="emailInvite()" :disabled="!canEmail">Yes</button>
                                <button class="button box-button" @click="closeModal()" :disabled="!canEmail">No</button>
                            </div>   
                            <div v-if="emailResult" class="password-warning">
                                <span>{{ emailResult }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>
<script>

export default {
    name: "UserActions",
    emits: [

    ],
    props: ['user'],
    data() {
        return {
            menuOpen: false,
            modalVisible: false,
            modalMode: null,
            viewPassword: false,
            passwordValid: false,
            passwordError: null,
            newPassword: null,
            newPassword2: null,
            passwordChanged: false,
            changeError: null,
            emailResult: null,
            canEmail: true
        };
    },
    created() {
        window.addEventListener("click", this.close);
    },

    beforeUnmount() {
        window.removeEventListener("click", this.close);
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen
        },
        toggleModal(mode) {
            if (this.modalMode == mode) {
                this.closeModal()
            } else {
                this.menuOpen = false;
                this.modalMode = mode
                this.modalVisible = true;
            }

        },
        closeModal() {
            this.modalVisible = false
            this.modalMode = null
            this.viewPassword = false
            this.passwordValid = false
            this.passwordError = null
            this.newPassword = null
            this.newPassword2 = null
            this.passwordChanged = false
            this.changeError = null
            this.emailResult = null
            this.canEmail = true
        },
        validatePassword() {
            this.passwordError = null
            this.passwordValid = false
            if (this.newPassword && this.newPassword.length < 8) {
                this.passwordError = "Password must be at least 8 charaters long"
            } else if (this.newPassword && this.newPassword.includes(' ')) {
                this.passwordError = "Password cannot include spaces"
            } else {
                this.passwordValid = true
            }
        },
        toggleViewPassword(){
            this.viewPassword = !this.viewPassword
        },
        async changePassword() {
            let api_path = 'users/' + this.user.id + '/password'
            try {
                let res = await this.$axios.patch(api_path,
                    { 'password': this.newPassword },
                    { 'headers': { "X-CSRF": this.$store.state.auth.csrf } }
                )
                console.log(res);
                if (res.status == 200) {
                    this.newPassword = null
                    this.newPassword2 = null
                    this.passwordChanged = true;
                } else {
                    this.changeError = "Password could not be changed"
                }
            } catch {
                this.changeError = "Password could not be changed"
            }

        },
        async emailInvite() {
            this.canEmail = false
            let res = await this.$axios.post('email-activate',
                { 'id': this.user.id },
                { 'headers': { "X-CSRF": this.$store.state.auth.csrf } }
            )
            console.log(res)
            if (res.status == 200) {
                this.emailResult = "Email invite sent"
            } else {
                this.emailResult = "Failed to send email invite"
            }
        },
        close(e) {
            if (!this.$refs.actions.contains(e.target) && this.menuOpen) {
                this.menuOpen = false;
                console.log('lele');
            }
        },
        clickHandler(event){
            console.log('clock');
            if(!event.target.closest(".modal-container")) {
                this.closeModal();
                console.log('clsick');
            }
        },
    },
    computed: {
        passwordsMatch() {
            if (this.newPassword && this.newPassword == this.newPassword2) {
                return true
            } else {
                return false
            }
        }

    },
    watch: {

    },
}

</script>
<style scoped lang="scss">

.user-actions.mobile{
    padding-top: 2em;
    button{
        margin-top: 1em;
    }
}
.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 3000;
}

.modal-outer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0px;
    top: 0px;
    height: 100%;
    align-items: center;
}

.user-actions-modal {
    min-width: 200px;
    z-index: 3001;
    min-height: 500px;
}

.modal-actions{
    padding-top: 30px;
    button{
        padding: 10px 30px;
        margin-right: 1em;
    }
}

.modal-inner {
    width: 100%;
    min-width: 400px;
    background: white;
    border: 1px solid black;
    position: relative;
    overflow-y: auto;
    padding: var(--pageRowGap) var(--pageMargins);
}

.modal-inner::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

.modal-inner::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
}

.modal-inner::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 0;
}

.new-password {
    width: 100%;
}

.password-warning {
    min-height: 50px;
    color: red;
}

.modal-text{
    font-size: 1.25em;
    line-height: 1.3;
}


.email-buttons{
    padding-top: 40px;
}

.email-button {
    padding: 15px;
    width: 75px;
    text-align: center;
    margin-right: 15px;
    background-color: black;
    color: white;
}

.email-button:hover {
    color: black;
    background-color: var(--MGYellowGreen);
}

.toggle-password{
    padding: .5em 0 0;
}

.form-action-buttons{
    padding-top: 60px;
}

.close-button {
    width: 48px;
    height: 48px;
    padding: 9px;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
        svg {
            opacity: .7;
        }
    }
}
</style>