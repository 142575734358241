import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'
import HomeView from '../views/HomeView.vue'

const LookbooksChildView = () => import(/* webpackChunkName: "styles-views" */ '../views/LookbooksChildView.vue')
const LinesheetsView = () => import(/* webpackChunkName: "styles-views" */ '../views/LinesheetsView.vue')
const LinesheetsChildView = () => import(/* webpackChunkName: "styles-views" */ '../views/LinesheetsChildView.vue')
const LinesheetsChildPDF = () => import(/* webpackChunkName: "styles-views" */ '../views/LinesheetsChildPDF.vue')
const StylesChildView = () => import(/* webpackChunkName: "styles-views" */ '../views/StylesChildView.vue')
const SearchView = () => import(/* webpackChunkName: "styles-views" */ '../views/SearchView.vue')

const OrderView = () => import(/* webpackChunkName: "order-views" */ '../views/OrderView.vue')
const PoOrderView = () => import(/* webpackChunkName: "order-views" */ '../views/PoOrderView.vue')
const AccountView = () => import(/* webpackChunkName: "order-views" */ '../views/AccountView.vue')
const AccountOrders = () => import(/* webpackChunkName: "order-views" */ '../views/AccountOrders.vue')
const AccountAddresses = () => import(/* webpackChunkName: "order-views" */ '../views/AccountAddresses.vue')
const AccountDetails = () => import(/* webpackChunkName: "order-views" */ '../views/AccountDetails.vue')

import AdminOrders from '../views/AdminOrders.vue'
import AdminUsers from '../views/AdminUsers.vue'
import AdminView from '../views/AdminView.vue'

const DefaultPage = () => import(/* webpackChunkName: "info-views" */ '../views/DefaultPage.vue')
const HelpView = () => import(/* webpackChunkName: "info-views" */ '../views/HelpView.vue')
const HelpChild = () => import(/* webpackChunkName: "info-views" */ '../views/HelpChild.vue')
const orderSubmitted = () => import(/* webpackChunkName: "info-views" */ '../views/orderSubmitted.vue')
const orderSubmittedPo = () => import(/* webpackChunkName: "info-views" */ '../views/orderSubmittedPo.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/linesheets',
    name: 'linesheets',
    component: LinesheetsView,
  },
  {
    path: '/linesheets/:childPage',
    name: 'linesheetsChild',
    component: LinesheetsChildView,
    props: true,
  },
  {
    path: '/linesheets/pdf/:childPage',
    name: 'linesheetsChildPDF',
    component: LinesheetsChildPDF,
    props: true,
  },
  {
    path: '/lookbooks/:childPage',
    name: 'lookbooksChild',
    component: LookbooksChildView,
    props: true,
  },
  {
    path: '/styles/:childPage',
    name: 'stylesChild',
    component: StylesChildView,
    props: true,
  },
  {
    path: '/order',
    name: 'order',
    component: OrderView,
  },
  {
    path: '/po-order',
    name: 'po-order',
    component: PoOrderView,
  },
  {  
    path: '/order-view/:orderNumber',
    name: 'orderSubmitted',
    component: orderSubmitted,
    props: true,
  },
  {  
    path: '/po-order-view/:orderNumber',
    name: 'orderSubmittedPo',
    component: orderSubmittedPo,
    props: true,
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    children: [
      {
        path: 'orders',
        component: AccountOrders,
      },
      {
        path: 'addresses',
        component: AccountAddresses,
      },
      {
        path: 'account-details',
        component: AccountDetails,
      }
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    children: [
      {
        path: 'orders',
        component: AdminOrders,
      },
      {
        path: 'users',
        component: AdminUsers,
      },
      {
        path: 'account-details',
        component: AccountDetails,
      }
    ],
  },
  {
    path: '/help',
    name: 'help',
    component: HelpView,
    children: [
      {
        name: 'helpchild',
        path: ':pageSlug',
        component: HelpChild,
        props: true
      }
    ],
  },
  {
    path: '/pages/:pageSlug',
    name: 'default',
    component: DefaultPage,
    props: true,
  },
  {
    path: '/search/:searchTerm',
    name: 'search',
    component: SearchView,
    props: true,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log("Initial csrf = " + typeof store.state.auth.csrf)
  if (Boolean(store.state.auth.csrf) === false) {
    await store.dispatch("getCsrf")
    console.log("Requested csrf = " + store.state.auth.csrf)
    if (Boolean(store.state.auth.csrf) === false) {
      window.location.href = process.env.VUE_APP_AXIOS_BASE+"login"
    } else {
      next()
    }
    // next()

  } else {
    next()
  }
});

export default router
