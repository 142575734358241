// import './assets/style.css';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './kirby'
import helper from './helper'
import matomo_config from './matomo'
import { createMetaManager } from 'vue-meta'
import VueMatomo from 'vue-matomo'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

const app = createApp(App).use(store).use(router).use(createMetaManager());
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$helper = helper;
app.config.globalProperties.$devRoot = process.env.DEV_SITE_ROOT;
// if(process.env.DEV_SITE_ROOT == '/') {
	app.use(VueMatomo, matomo_config);
// }
app.use(VueClipboard);
app.mount('#app');
