<template>
	<header class="header mobile-header">
		<div class="mobile-header-inner">
			<h1 class="mobile-title">
				<router-link to="/">
					<MGLogo></MGLogo>
				</router-link>
				<span class="visuallyhidden">Molly Goddard</span>
			</h1>
			<nav>
				<details ref="mobileMenuContainer" class="mobile-menu-container">
					<summary class="mobile-menu-button">
						<span class="visuallyhidden">Main Menu</span>
						<span class="bar"></span>
						<span class="bar"></span>
					</summary>
					<ul class="mobile-menu main-menu subhead caps">
						<li class="main-menu-item" v-for="item in menu_items" :key="item.title">
							<details v-if="item.subMenu" class="mobile-sub-menu" @click.prevent="openSubMenu">
								<summary>
									{{ item.title }}
								</summary>
								<ul class="submenu">
									<li v-for="subItem in item.subMenu" :key="subItem.title">
										<router-link :to="subItem.link || '/'"> {{ subItem.title }} </router-link>
									</li>
								</ul>
							</details>
							<router-link v-else :to="item.link || '/'">
								{{ item.title }}
							</router-link>
						</li>
						<li class="main-menu-item">
							<router-link :to="{ name: 'help' }">
								<span class="icon-text">help</span>
							</router-link>
						</li>
						<li class="main-menu-item" v-if="user_role != 'admin'">
							<router-link :to="{ name: 'account' }">
								<span class="icon-text">account</span>
							</router-link>
						</li>
					</ul>
				</details>
				<div class="mobile-icon-menu">

					<router-link :to="{ name: orderPage }" class="mobile-menu-icon-link subhead caps">
							<span class="icon-text visuallyhidden">order</span>
							<span class="order-total">{{ order_total }}</span>
							<img src="../assets/img/cart-icon-3.svg" />
					</router-link>

					<AccountMenuDropdown v-if="user_role !='admin'" class="xs-hide sm-show"></AccountMenuDropdown>
					<AdminMenuDropdown v-else class="xs-hide sm-show"></AdminMenuDropdown>

					<button @click="toggleSearch" class="mobile-menu-icon-link xs-hide md-show">
						<span class="icon-text visuallyhidden">search</span>
						<img src="../assets/img/search-icon.svg" />
					</button>

					<router-link :to="{ name: 'help' }" class="mobile-menu-icon-link xs-hide md-show">
						<span class="icon-text visuallyhidden">help</span>
						<img src="../assets/img/help-icon-1.svg" />
					</router-link>

					<button @click="toggle_role()" v-if="user_role == 'admin'" aria-label="Switch between personal and Wholesale mode"  class="mobile-menu-icon-link subhead caps xs-hide sm-block">
							<span v-if="is_po" class="icon-text order-total">P</span>
							<span v-else class="icon-text order-total">W</span>
							<img src="../assets/img/cart-icon-3.svg" />
					</button>

				</div>
			</nav>
		</div>

	</header>
	<header class="header desktop-header">
		<div class="page-grid">
			<h1 class="xs-col-12 lg-col-3">
				<router-link to="/">
					<MGLogo></MGLogo>
				</router-link>
				<span class="visuallyhidden">Molly Goddard</span>
			</h1>
			<nav v-if="menu_items" class="lg-col-9 nav-grid">
				<ul class="main-menu subhead caps">
					<li v-if="user_role == 'admin'" class="xs-hide lg-inline-block xl-hide">
						<button @click="toggle_role()">
							<span v-if="is_po" class="text-red">PERSONAL</span>
							<span v-else class="text-green">WHOLESALE</span>
						</button>
					</li>
					<li class="main-menu-item" v-for="item in menu_items" :key="item.title"
						@mouseover="hover = item.title" @mouseleave="hover = null">
						<details @mouseenter="showMenu" @mouseleave="hideMenu" v-if="item.subMenu">
							<summary>
								{{ item.title }}
							</summary>
							<div class="submenu">
								<div v-for="subItem in item.subMenu" :key="subItem.title">
									<router-link :to="subItem.link || '/'"> {{ subItem.title }} </router-link>
								</div>
							</div>
						</details>
						<router-link v-else :to="item.link || '/'">
							{{ item.title }}
						</router-link>
					</li>
					<li class="main-menu-item">
						<button @click="toggleSearch">SEARCH</button>
					</li>
				</ul>
				<ul class="user-menu subhead caps">
					<li v-if="user_role == 'admin'" class="xs-hide lg-hide xl-inline-block">
						<button @click="tryToggleRole()">
							<span v-if="is_po" class="text-red">PERSONAL</span>
							<span v-else class="text-green">WHOLESALE</span>
						</button>
					</li>
					<li>
						<router-link :to="{ name: 'help' }">
							<span class="icon-text">help</span>
						</router-link>
					</li>
					<li v-if="user_role != 'admin'">
						<router-link :to="{ name: 'account' }">
							<span class="icon-text">account</span>
						</router-link>
					</li>
					<li v-else>
						<details class="details" @mouseenter="showMenu" @mouseleave="hideMenu">
							<summary>
								Admin
							</summary>
							<div class="submenu right">
								<router-link to="/admin/orders">Orders</router-link>
								<router-link to="/admin/users">Users</router-link>
								<router-link to="/admin/account-details">Details</router-link>
								<a href="#" @click="logout">Sign Out </a>
							</div>
						</details>
					</li>
					<li>
						<router-link :to="{ name: orderPage }">
							<span class="icon-text">order - {{ order_total }}</span>
						</router-link>
					</li>
				</ul>
			</nav>
		</div>
	</header>

	<SearchDialog :modalVisible="searchVisible" @close="toggleSearch"/>
	
	<PoDialog :modalVisible="poDialogVisible" @close="togglePoDialog"/>

</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MGLogo from '@/components/MGLogo.vue'
import AdminMenuDropdown from '@/components/navbar/AdminMenuDropdown.vue'
import AccountMenuDropdown from '@/components/navbar/AccountMenuDropdown.vue'
import SearchDialog from '@/components/navbar/SearchDialog.vue'
import PoDialog from '@/components/navbar/PoToggleDialog.vue'
// import { forEach } from 'mathjs'
export default {
	name: 'NavBar',
	components: {
		MGLogo,
		AdminMenuDropdown,
		AccountMenuDropdown,
		SearchDialog,
		PoDialog
	},
	computed: {
		...mapGetters({
			getTotalStyles: 'orders/getTotalStyles',
			is_po: 'is_po',
			getOrderTotals: 'orders/getOrderTotals'
		}),
		...mapState({
			user_role: state => state.auth.role,
		}),
		orderPage(){
			if(this.is_po){
				return 'po-order'
			} else {
				return 'order'
			}
		},
		order_total(){
			if(this.is_po){
				return this.getOrderTotals.total_items
			} else {
				return this.getTotalStyles
			}
		}
	},
	data() {
		return {
			menu_items: null,
			hover: null,
			searchTerm: null,
			searchVisible: false,
			poDialogVisible: false,
		}
	},
	async mounted() {
		let res = await this.$axios.get("/site-menu", { 'headers': { 'X-CSRF': this.$store.state.auth.csrf } });
		this.menu_items = res.data;
	},
	methods: {
		...mapActions([
			'toggle_role'
		]),
		searchStyles(event) {
			event.preventDefault();
			console.log(this.searchTerm)
			this.$router.push({ name: 'search', params: { searchTerm: this.searchTerm } })

		},
		toggleMenu(event) {
			let details = event.target.closest('details');
			details.open = !details.open;
		},
		hideMobileMenu(event) {
			let details = event.target.closest('details');
			let open = details.open;
			details.open = !open;
		},
		showMenu(event) {
			event.target.closest('details').open = true;
		},
		hideMenu(event) {
			event.target.closest('details').open = false;
		},
		openSubMenu(event) {
			let details = event.target.closest('details');
			console.log(details);
			let open = details.open;
			details.open = !open;
		},
		toggleSearch(){
			this.searchVisible = !this.searchVisible;
		},
		togglePoDialog(){
			this.poDialogVisible = !this.poDialogVisible;
		},
		tryToggleRole(){
			if(!this.is_po && this.getOrderTotals.empty_styles){
				this.togglePoDialog()
			} else {
				this.toggle_role()
			}
		},
		logout() {
            this.$store.dispatch("logout");
        },
	},
	watch: {
		$route() {
			this.$refs.mobileMenuContainer.open = false;
		}
	}

}

</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

.desktop-header {
	display: none;
}

.mobile-header {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: rgba(255, 255, 255, 1);
	padding: var(--pageMargins);
}

.mobile-header-inner {
	position: relative;
	width: 100%;
}

.mobile-title {
	position: absolute;
	width: 150px;
	left: 50%;
	transform: translateX(-50%);
	top: 1px;
	svg {
		width: 100%;
	}
}

.mobile-menu-button {
	width: 22px;
	height: 22px;
	position: relative;

	.bar {
		transition: transform 100ms ease;
	}

	.bar:nth-child(2) {
		height: 2px;
		width: 100%;
		background: black;
		position: absolute;
		top: 6px;
	}

	.bar:nth-child(3) {
		height: 2px;
		width: 100%;
		background: black;
		position: absolute;
		top: 12px;
	}
}

.mobile-menu-container{
	padding-top: 2px;
}

.mobile-menu-container[open] {
	.mobile-menu-button {
		.bar:nth-child(2) {
			top: 10px;
			transform: rotate(45deg);
		}

		.bar:nth-child(3) {
			top: 10px;
			transform: rotate(-45deg);
		}
	}
}

.mobile-menu {
	position: fixed;
	top: 54px;
	left: 0px;
	width: 100%;
	background: white;
	padding: var(--pageMargins);
	height: 100vh;

	summary {
		cursor: pointer;

		&:hover {
			color: var(--hover);
		}
	}

	a {
		display: block;
	}

	li {
		min-height: 48px;
		line-height: 48px;
		border-top: 1px solid var(--lightGreyBG);

		&:last-child {
			border-bottom: 1px solid var(--lightGreyBG);
		}
	}

	.submenu li {
		padding-left: 2em;

		&:last-child {
			border-bottom: 0;
		}
	}
}

.mobile-icon-menu {
	position: absolute;
	top: 0;
	right: 0;
	a, button {
		float: right;
	}
}

.mobile-menu-icon-link {
	width: 24px;
	height: 24px;
	text-align: center;
	margin-left: .5em;
	border-radius: 50%;
	position: relative;
	img {
		width: 24px;
		position: absolute;
		top: 0;
		right: 0;
	}
	.order-total {
		position: absolute;
		top: 0px;
		right: 0px;
		font-size: .925em;
		width: 100%;
		text-align: center;
		height: 100%;
		line-height: 28px;
		display: block;
		border-radius: 50%;
	}
	&:hover {
		background: var(--MGLightYellowGreen);
		color: black;
	}
}


.navbar-search {
	position: relative;

	::-webkit-input-placeholder {
		/* Edge */
		color: transparent;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: transparent;
	}

	::placeholder {
		color: transparent;
	}

	label {
		opacity: 1;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0;
		height: auto;
		border: 0;
		text-transform: uppercase;
		color: transparent;
		width: 100%;

		// border: 1px solid saddlebrown;
		&:focus {
			outline: 0;
			padding-bottom: 1px;
			border-bottom: 2px solid black;
			color: black;
		}
	}

	input:focus::placeholder {
		color: transparent;
	}

	input:focus+label {
		opacity: 0;
	}
}

.navbar-search:focus-within {
	label {
		color: transparent;
	}
}

.navbar-search:hover {
	label {
		color: var(--hover);
	}
}

@media only screen and (min-width: nth($md, 2)) {

	.mobile-header {
		padding: 16px var(--pageMargins);
		height: 65px;
	}

	.mobile-title {
		width: 215px;
		top: 4px;

		svg {
			width: 100%;
		}
	}

	.mobile-icon-menu {
		position: absolute;
		right: 0;
		top: 0;
	}

}

@media only screen and (min-width: nth($lg, 2)) {

	.mobile-header {
		display: none;
	}

	.desktop-header {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		background: rgba(255, 255, 255, 1);
	}

	nav {
		text-align: right;

		ul {
			display: inline-block;
			text-align: right;
		}
	}

	header {
		padding-top: 16px;

		h1 {
			width: 100%;
		}
	}

	h1 {
		max-width: 213px;
	}

	li {
		display: inline-block;
		padding-left: var(--pageColumnGap);
	}

	summary {
		cursor: pointer;
		pointer-events: none;

		&:hover {
			color: var(--hover);
		}
	}

	.details{
		position: relative;
	}

	.submenu {
		position: absolute;
		min-width: 100px;
		text-align: left;

		li {
			display: block;
		}

		a {
			display: block;
			width: 100%;
		}
	}

	.submenu.right{
		position: absolute;
		right: 0;
		text-align: right;
	}

	.order-total-icon {
		padding: 0 0 0 .15em;
		// color: red;
	}

}

@media only screen and (min-width: nth($xl, 2)) {

	nav {
		display: grid;
		grid-template-columns: 2fr 1fr;
	}

	.main-menu {
		text-align: left;

		li {
			padding-left: 0;
			padding-right: var(--pageColumnGap);
		}
	}

	.navbar-search {
		input {
			min-width: 100px;
		}
	}

	.user-menu {
		text-align: right;

		li {
			padding-right: 0px;
			padding-left: var(--pageColumnGap);
		}
	}


}

@media print {
	.header {
		display: none;
	}
}
</style>