<template>
    <transition name="fast"> 
        <div class="modal-mask" v-show="modalVisible" @click="closeModal()"></div>
    </transition>
    <transition name="fast"> 
        <div class="modal" v-show="modalVisible" @click="closeModal()">
            <div class="modal-inner" @click.stop="null">
                <button class="close-button visuallyhidden" @click="closeModal()">
                    <span class="visuallyhidden">CLOSE</span>
                </button>
                <div class="dialog-search" @submit.prevent="searchStyles" ref="form">
                    <h3 class="subhead caps">Warning</h3>
                    <p>You have styles in you order without quanities. <br />
                    Please remove these styles or select quantities before switching to Personal Mode</p>
                    <button @click="closeModal()" class="button box-button medium-button">Okay</button>
                </div>

            </div>
        </div>
    </transition>

</template>
<script>
	export default {
    name: "PoDialog",
    emits: ['close'],
    props: ['modalVisible'],
    data() {
        return {
            
        }
    },
    methods: {
        closeModal(){
            this.$router.push({ name: 'order' })
            this.$emit('close');
        }
    },
    mounted() {
       
    },
}

</script>
<style scoped lang="scss">

    .toggle-button{
        margin-left: var(--pageColumnGap);
        font-family: "FranklinGothicMediumCondensed", sans-serif;
    }
  
    .modal-mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.4);
        z-index: 3000;
    }

    .modal{
        width: 100%;
        padding: var(--pageRowGap) var(--pageMargins) 0;
        z-index: 3001;
        left:0;
        top:50%;
        position: fixed;
        transform: translateY(-50%);
    }

    .button{
        margin-top: 1em;
        margin-bottom: 0px;
        padding: 5px 25px;
        height: auto;
    }

    .modal-inner{
        width: 100%;
        max-width: 730px;
        background: white;
        // border: 1px solid black;
        position: relative;
        margin: 0 auto;
        padding: var(--pageColumnGap);
    }

    .modal-inner::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }
    
    .modal-inner::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 0;
    }
    
    .modal-inner::-webkit-scrollbar-thumb {
        background: #000;
        border-radius: 0;
    }

    .close-button{
        width: 48px;
        height: 48px;
        padding: 9px;
        position: absolute;
        top:0;
        right: 0;
        &:hover{
            svg{
                opacity: .7;
            }
        }
    }

    label{
        display: block;
        padding: 0 0 var(--pageRowGap);
    }

    .input-wrapper{
        position: relative;
    }

    .search-button{
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 60px;
        text-indent:-999px;
        font-size:0px; line-height:0;
        padding: 20px;
    }

    input{
        width: 100%;
        font-size: 1.75em;
        padding: 15px 60px 10px 15px;
        border-color: black;
        height: 60px;
        &:focus{
            outline: 0;
        }
    }

    .modal-buttons{
        text-align: right;
    }

</style>