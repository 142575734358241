<template>
  <div class="home-container page-container">
    <header class="page-header">
      <h2 class="page-grid">
        <span class="xs-col-6 md-col-6 xl-col-3 caps subhead">
          Wholesale Showroom
        </span>
        <span class="xs-col-6 md-col-6 caps subhead season">
          {{current_season}}
        </span>
      </h2>
    </header>
    <section class="home-images">
       <div class="page-grid">
        <div class="spacer xs-hide xl-show xl-col-3"></div>
        <div v-for="image in images" :key="image.id" class="image-container xs-col-12 lg-col-6 xl-col-3">
              <img :srcset="image.image.srcset">
        </div>
        <!-- <div class="image-container xs-col-12 sm-col-6 xl-col-3"></div>
        <div class="image-container xs-hide sm-show sm-col-6 xl-col-3"></div>
        <div class="image-container xs-hide xl-show xl-col-3"></div> -->
       </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  props: ['pageSlug'],
  data () {
		return {
      current_season: null,
      images: null
    }
	},
  components: {
  },
  methods: {
    // async api() {
    //   console.log(this.$store.state.auth.csrf);
    //   let data = {
    //     query: "page('addresses').children.filterBy('customer_id', 'qp2EhhNW')",
    //     select: {
    //       address: "page.address",
    //       name: "page.title",
    //       slug: "page.slug"
    //     }
    //   }
    //   let api = await this.$axios.post("query/", data, {'headers': {'X-CSRF': this.$store.state.auth.csrf}});
    //   console.log(api.data.data.content);
    // }
  },
  async mounted () {
      let site = await this.$axios.get("/site", {'headers': {'X-CSRF': this.$store.state.auth.csrf}});
			console.log(site.data.data.content);
      this.current_season = site.data.data.content.subtitle;
      this.images = site.data.data.content.homepageimages;
      console.log(this.images);
	}
}
</script>

<style scoped lang="scss">
  @import "@/assets/sass/variables";

  .image-container{
    // background: orange;
    width: 100%;
    padding-bottom: 150%;
    position: relative;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .season{
    text-align: right;
  }

  .image-container{
    &:nth-child(2){
      display: none;
    }
    &:nth-child(3){
      display: none;
    }
  }


  @media only screen and (min-width: nth($lg, 2)) {
    
    .season{
      text-align: left;
    }

    .image-container{
      &:nth-child(2){
        display: block;
      }
    }

  }

  @media only screen and (min-width: nth($xl, 2)) {

    .image-container{
      &:nth-child(3){
        display: block;
      }
    }

  }


</style>