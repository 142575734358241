import axios from 'axios'
import store from '../store/index.js'

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE+process.env.VUE_APP_DEV_API;

axios.defaults.validateStatus = function (status) {
	if (status == 403) {
		store.commit('add_csrf', null);
		window.location.href = process.env.VUE_APP_AXIOS_BASE+"login";
		return false
	} else {
		return status < 400;
	}
};

export default axios