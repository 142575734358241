<template>
    <h3 class="subhead caps page-header">ORDERS</h3>
    <div class="order-filters">
        <span class="form-input">
            <label class="select-label visuallyhidden" for="select-season">Season:</label>
            <div class="select-wrapper">
                <div class="display-label">Season: <span class="caps">{{selected_season}}</span></div>
                <select if="select-season" v-model="selected_season">
                    <option v-for="season in seasons" :key="season" :value="season">
                       {{season}}
                    </option>
                </select>
                <span class="icon">
                    <SmallArrow></SmallArrow>
                </span>
            </div>
        </span>
        <span class="form-input">
            <label class="select-label visuallyhidden" for="select-status">Status:</label>        
            <div class="select-wrapper">
                <div class="display-label">Status: <span class="caps">{{convertStatus(selected_status)}}</span></div>
                <select id="select-status" v-model="selected_status">
                    <option value="all" selected>
                       ALL
                    </option>
                    <option value="draft">
                       Draft
                    </option>
                    <option value="unlisted">
                       Submitted
                    </option>
                    <option value="listed">
                       Approved
                    </option>
                </select>
                <span class="icon">
                    <SmallArrow></SmallArrow>
                </span>
            </div>
        </span>
        <span class="form-input">
            <label class="select-label visuallyhidden" for="select-customer">Customer:</label>
            <div class="select-wrapper">
                <div class="display-label">Customer: <span class="caps">{{getUsername(selected_customer)}}</span></div>
                <select id="select-customer" v-model="selected_customer">
                    <option value="all" selected>
                       ALL
                    </option>
                    <option v-for="user in users" :key="user.id" :value="user.id">
                       {{user.name}}
                    </option>
                </select>
                <span class="icon">
                    <SmallArrow></SmallArrow>
                </span>
            </div>
        </span>
        <span class="form-input">
            <label class="select-label visuallyhidden" for="select-type">Type:</label>        
            <div class="select-wrapper">
                <div class="display-label">Type: <span class="caps">{{convertType(selected_type)}}</span></div>
                <select id="select-type" v-model="selected_type">
                    <option value="all" selected>
                       ALL
                    </option>
                    <option value=0>
                       Wholesale
                    </option>
                    <option value=1>
                       Personal
                    </option>
                </select>
                <span class="icon">
                    <SmallArrow></SmallArrow>
                </span>
            </div>
        </span>
    </div>


    <transition>
        <div v-if="loaded" class="table">
        <h2 class="subhead caps page-header visuallyhidden">Orders:</h2>
        <div class="tableheader subhead" aria-hidden="true">
            <div class="order-number">Order No. / Name</div>
            <div class="order-date"><button @click="date_asc = !date_asc">Date</button></div>
            <div class="order-summary">Quantity</div>
            <div class="order-customer">Customer</div>
            <div class="order-total">Total</div>
            <div class="order-status">Status</div>
        </div>
        <ul class="orders-list">
            <li v-for="order in filteredOrders" :key="order.order_number" class="order-list-item">
                <div class="grid">
                    <div class="order-number"><span class="xl-hide row-title">Order No./Name:</span>
                        <span v-if="order.status != 'draft' || order.po">{{order.slug}}</span>
                        <span v-else>{{order.order_name}}</span>
                    </div>
                    <div class="order-date"><span class="xl-hide row-title">Date:</span>{{convertToDate(order.updated)}}</div>
                    <div class="order-summary"><span class="xl-hide row-title">Quantity:</span>{{order.style_count}} Styles, {{order.item_count}} Units</div>
                    <div class="order-customer"><span class="xl-hide row-title">Customer:</span>{{getUsername(order.customer)}}</div>
                    <div class="order-total"><span class="xl-hide row-title">Order Total:</span>GBP £{{getTotal(order)}}</div>
                    <div class="order-status">
                        <span class="xl-hide row-title">Status:</span>
                        <span :class="order.status">{{convertStatus(order.po, order.status)}}</span>
                        <span class="processing icon"> </span>
                    </div>
                    <OrderActions :order="order" @delete="deleteOrder"></OrderActions>
                </div>
            </li>
        </ul>
        </div>
        <div class="relative" v-else>
            <div class="loader">
            <LoadingElement></LoadingElement>
            <span class="visuallyhidden">Loading...</span>
            </div>  
        </div>
    </transition>
</template>

<script>
import SmallArrow from "../components/icons/SmallArrow.vue";
import LoadingElement from  '@/components/loader.vue'
import OrderActions from '@/components/admin/OrderActions.vue'
export default {
	name: "AdminUsers",
	data() {
		return{
            seasons: [],
            users: [],
            selected_season: null,
            selected_status: "all",
            selected_customer: "all",
            selected_type: "all",
            date_asc: true,
            orders: [],
            filteredOrders: [],
            active_season: null,
            loaded: false,
            no_click: false,
        }
        
	},
    components:{
        SmallArrow,
        LoadingElement,
        OrderActions,
    },
    watch: {
        selected_status(){
            this.filterOrders()
        },
        selected_customer(){
            this.filterOrders()
        },
        selected_season(){
            this.filterOrders()
        },
        date_asc(){
            this.filterOrders()
        },
        selected_type(){
            this.filterOrders()
        }
    },
    computed:{},
    methods: {
        filterOrders(){
            let filtered = this.orders;
            filtered.sort((a, b) => {
                if(this.date_asc){
                    if (a.updated > b.updated){
                      return -1
                    }
                    if (a.updated < b.updated) {
                      return 1
                    }
                    return 0
                } else {
                    if (a.updated < b.updated){
                      return -1
                    }
                    if (a.updated > b.updated) {
                      return 1
                    }
                    return 0
                }
            })
            console.log(filtered)
            if (this.selected_customer !== 'all'){
                filtered = filtered.filter(order => order.customer == this.selected_customer);
            }
            if (this.selected_status !== 'all'){
                filtered = filtered.filter(order => order.status == this.selected_status);
            }
            if (this.selected_type !== 'all'){
                filtered = filtered.filter(order => Boolean(order.po) == parseInt(this.selected_type));
            }
            this.filteredOrders = filtered.filter(order => order.season == this.selected_season); 
        },
        convertStatus(is_po, status){
            if(status == "unlisted" && is_po){
                return "Paid"
            }
            if(status == "draft"){
                return "Draft";
            }
            if(status == "listed"){
                return "Approved";
            }
            if(status == "unlisted"){
                return "Processing"
            }
            return "All"
        },
        convertType(status){
            if(status == "all"){
                return "ALL"
            }
            if(parseInt(status) == 0){
                return "Wholesale"
            }
            if(parseInt(status) == 1){
                return "Personal"
            }
        },
        getTotal(order){
            let total
            if(order.po && order.po_shipping_cost){
                total = parseFloat(order.total_cost) + parseFloat(order.po_shipping_cost);
            } else {
                total = order.total_cost;
            }
            return this.convertToCurrency(total);
        },
        convertToCurrency(total) {
            total = parseInt(total)
            return total.toLocaleString('en');
        },
        convertToDate(timestamp) {
            timestamp = parseInt(timestamp)
            let date = new Date(timestamp);
            let date_str = date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
            return date_str;
        },
        getUsername(id) {
            if (id.charAt(0) == '-'){
                id = id.replace('- ','').replace('\n', '')
            }
            let user = this.users.find(user => user.id == id);
            if (user){
                return user.name
            } else {
                return null
            }
        },
        async deleteOrder(orderNumber){
            console.log("deleting order "+orderNumber)
            let res = await this.$axios.delete("/pages/orders+"+orderNumber, {headers: {'X-CSRF': this.$store.state.auth.csrf}});
            console.log(res);
            if(res){
                let orderIndex = null;
                for(var i=0;i<this.orders.length;i++){
                    if(this.orders[i].slug == orderNumber){
                        orderIndex = i;
                        break
                    }
                }
                this.orders.splice(i, 1);
                this.filterOrders();
                console.log(orderIndex)
            }
            this.no_click = false
        },
    },
	async mounted() {
        let res = await this.$axios.get("/pages/seasons/children", {params:{select:'title'}, headers: {'X-CSRF': this.$store.state.auth.csrf}});
        console.log(res)
        res.data.data.forEach(function(season){
            this.seasons.push(season.title);
        }, this)
        let site  = await this.$axios.get("/site", {params:{select:'content'}, headers: {'X-CSRF': this.$store.state.auth.csrf}});
        this.selected_season = site.data.data.content.currentseason[0].text;
        this.active_season = site.data.data.content.currentseason[0].text;
		console.log(this.selected_customer);

        if (this.$store.state.auth.role == 'admin'){
            let user_res = await this.$axios.get("get-user/account", {
                headers: { "X-CSRF": this.$store.state.auth.csrf },
            });
            console.log(user_res.data);
            let users = user_res.data;
            this.users = users.filter(user => user.role != 'admin')

            let orders = await this.$axios.post("get-orders",{'impersonate':'all'}, {
                headers: { "X-CSRF": this.$store.state.auth.csrf },
            });
            this.orders = orders.data.orders;
            await this.filterOrders();
            this. loaded = true;

        }
        
	},
};
</script>
<style lang="scss" scoped>
    @import "@/assets/sass/variables";

    .order-filters{
        margin-bottom: var(--pageRowGap);
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--pageRowGap);
        .form-input{
            padding: 0;
            grid-column: span 1;
            .select-wrapper{
                border-color: var(--lightBorder);
                position: relative;
                &:hover{
                    background-color: var(--MGYellowGreen);
                    cursor: pointer;
                    .icon{
                        background: var(--MGYellowGreen);
                    }
                }
                .display-label{
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                select{
                    opacity: 0;
                    background: red;
                }
                .icon{
                    width: 24px;
                    padding: 15px 10px 20px 0px;
                    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%);
                }
            }
        }
    }

    .table{
        margin-top: 48px;
    }

@media only screen and (min-width: nth($md, 2)) {

    .order-filters{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

}

.relative{
        width: 100%;
        position: relative;
    }

@media only screen and (max-width: nth($xl, 2)) {
    .tableheader{
        display: none;
    }

    .order-list-item{
        padding: 15px;
        border: 1px solid black;
        margin-bottom: 15px;
        line-height: 1.5;
        .row-title{
            font-weight: bold;
            width: 100px;
            display: inline-block;
        }
        .order-actions{
            padding: 30px 0 0;
            a{
                display: inline-block;
                margin-left: 1em
            }
        }
        .prompt{
            padding: 15px 0 0;
            span{
                display: block;
                padding: 0 0 15px;
            }
            button{
                height: 24px;
                line-height: 25px;
                padding: 0 20px;
                font-size: 12px;
                font-family: "FranklinGothicMedium", sans-serif;
                border: 1px solid black;
                text-align: center;
                margin-right: 1em;
            }
        }
    }
    }

@media only screen and (min-width: nth($xl, 2)) {
    

    .tableheader{
        line-height: 48px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr) 50px;
        border-top: 0;
        border-bottom: 1px solid var(--lightBorder);
    }
    .order-list-item:hover{
        background-color: var(--lightGreyBG);
    }
    .order-list-item .grid{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr) 50px;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid var(--lightBorder);
        border-left: 1px solid var(--lightBorder);
        border-right: 1px solid var(--lightBorder);
        div{
            overflow: hidden;
        }
        div:first-child{
            padding: 0 10px;
        }
    }
    .order-customer{
        grid-column: span 2;
    }
}

.draft {
    color: var(--draft);
}

.unlisted {
    color: var(--submitted);
}

.listed {
    color: var(--approved);
}


</style>