
    
<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 8"  preserveAspectRatio="xMinYMin meet">
        <path d="M0 0H14.1421L7.07107 7.07107L0 0Z" fill="black"/>
    </svg>
</template>
<script>
	export default {
		name: 'SmallArrow'
    }
</script>