export function gen_hash (input) {
	let new_id = new Date().getTime() + input;
    // hash id
    let seed = 0
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < new_id.length; i++) {
        ch = new_id.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    let new_id_hash = 4294967296 * (2097151 & h2) + (h1>>>0);
    return new_id_hash;
}

export default gen_hash;