<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <ul class="skip-links">
    <li>
      <a href="#main" class="skip-link" ref="skipLink">Skip to main content</a>
    </li>
  </ul>
	<NavBar v-if="hasCsrf"></NavBar>
  <main id="main" class="main">
    <router-view v-if="hasCsrf" />
  </main>
	<NavFooter v-if="hasCsrf"></NavFooter>
</template>

<script>
	import NavBar from '@/components/NavBar.vue'
	import NavFooter from '@/components/NavFooter2.vue'
  import { useMeta } from 'vue-meta'

	export default {
		components: {
			NavBar,
			NavFooter
		},
    setup () {
      useMeta({
        title: 'Molly Goddard Wholesale Showroom',
        htmlAttrs: {
          lang: 'en',
          amp: true
        }
      })
    },
		computed: {
			hasCsrf() {
				return Boolean(this.$store.state.auth.csrf);
			}
		},
    watch: {
      $route() {
        this.$refs.skipLink.focus()
      }
    },
    created() {
      function keyboardFocus (e) {
          if (e.keyCode !== 9) {
              return;
          }
          switch (e.target.nodeName.toLowerCase()) {
              case 'input':
              case 'select':
              case 'textarea':
                  break;
              default:
                  document.documentElement.classList.add('keyboard-focus');
                  document.removeEventListener('keydown', keyboardFocus, false);
          }
      }
      document.addEventListener('keydown', keyboardFocus, false);
    },
	}

</script>

<style lang="sass">

@import './assets/plyr.css';
@import './assets/sass/style.scss';



</style>
