<template>
    <h2 class="subhead caps page-header">Users</h2>
    <label for="search-users" class="visuallyhidden">Search Users</label>
    <input class="user-seach" type="text" name="search-users" v-model="searchTerm" placeholder="Search Users">
    <transition>
        <div v-if="loaded" class="table">
            <div class="tableheader subhead" aria-hidden="true">
                <p class="order-number">Name</p>
                <p class="order-date">Username</p>
                <p class="order-summary">Email</p>
                <p class="order-customer">Role</p>
            </div>
            <ul class="orders-list">
                <li v-for="user in filteredUsers" :key="user.id" class="order-list-item">
                    <div class="grid">
                        <div class="order-number"><span class="xl-hide row-title">Name:</span>{{user.name}}</div>
                        <div class="order-date"><span class="xl-hide row-title">Username:</span>{{user.username}}</div>
                        <div class="order-summary"><span class="xl-hide row-title">Email:</span>{{user.email}}</div>
                        <div class="order-customer"><span class="xl-hide row-title">Role:</span>{{user.role}}</div>
                        <UserActions :user="user"></UserActions>
                    </div>
                </li>
            </ul>
        </div>
        <div class="relative" v-else>
            <div class="loader">
            <LoadingElement></LoadingElement>
            <span class="visuallyhidden">Loading...</span>
            </div>  
        </div>
    </transition>
</template>

<script>
import UserActions from "@/components/admin/UserActions.vue"
import LoadingElement from  '@/components/loader.vue'
export default {
    name: "AdminUsers",
    data() {
        return {
            users: null,
            filteredUsers: null,
            searchTerm: null,
            loaded: false,
        }
        
    },
    watch: {
        searchTerm(){
            console.log(this.searchTerm)
            this.filteredUsers = this.users.filter(function(user){
                let name = user.name.toLowerCase()
                let username = user.username.toLowerCase()
                return name.includes(this.searchTerm) || username.includes(this.searchTerm) || user.role.includes(this.searchTerm)
            }, this)
        }
    },
    components:{
        UserActions,
        LoadingElement,
    },
    methods: {
    },
    async mounted() {
        if (this.$store.state.auth.role == 'admin'){
            let user_res = await this.$axios.get("get-user/account", {
                headers: { "X-CSRF": this.$store.state.auth.csrf },
            });
            console.log(user_res.data);
            this.users =  user_res.data;
            // let users = user_res.data;
            // this.users = users.filter(user => user.role != 'admin')
            this.filteredUsers = this.users
            this.loaded = true;
        }
    },
};
</script>
<style lang="scss" scoped>
    @import "@/assets/sass/variables";

    .relative{
        width: 100%;
        position: relative;
    }

    .user-seach {
      width: 100%;
      margin-bottom: 48px;
    }

@media only screen and (max-width: nth($xl, 2)) {
    .tableheader{
        display: none;
    }

    .order-list-item{
        padding: 15px;
        border: 1px solid black;
        margin-bottom: 15px;
        line-height: 1.5;
        .row-title{
            font-weight: bold;
            width: 100px;
            display: inline-block;
        }
        .order-actions{
            padding: 30px 0 0;
            a{
                display: inline-block;
                margin-left: 1em
            }
        }
        .prompt{
            padding: 15px 0 0;
            span{
                display: block;
                padding: 0 0 15px;
            }
            button{
                height: 24px;
                line-height: 25px;
                padding: 0 20px;
                font-size: 12px;
                font-family: "FranklinGothicMedium", sans-serif;
                border: 1px solid black;
                text-align: center;
                margin-right: 1em;
            }
        }
    }
}

@media only screen and (min-width: nth($xl, 2)) {

    .user-seach {
      width: 600px;
    }
    .tableheader{
        line-height: 48px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr) 50px;
        border-top: 0;
        border-bottom: 1px solid var(--lightBorder);
    }
    .order-list-item:hover{
        background-color: var(--lightGreyBG);
    }
    .order-list-item .grid{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr) 50px;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid var(--lightBorder);
        border-left: 1px solid var(--lightBorder);
        border-right: 1px solid var(--lightBorder);
        div{
            overflow: hidden;
        }
        div:first-child{
            padding: 0 10px;
        }
    }

}

</style>