import { createStore } from 'vuex'
// import { toRaw } from 'vue';
import axios from 'axios'
// import helper from '../helper'
import { matrix, sum, row } from 'mathjs'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'orders', 'review']
})

const auth = {
  state: {
    csrf: null,
    user: null,
    username: null,
    role: null,
    impersonate: null,
    impersonate_role:null,
    store_version: null,
  },
  getters: {
    site_user(state){
      if(state.impersonate){
        return state.impersonate
      } else {
        return state.user
      }
    },
    is_po(state){
      if (state.role == 'personal') {
        return true
      } else if (state.role == 'admin' && state.impersonate_role == 'personal'){
        return true
      } else {
        return false
      } 
    },
    has_customer(state){
      if (state.role != 'admin'){
        return true
      } else if (state.impersonate != null){
        return true
      } else {
        return false
      }
    }
  },
  mutations: {
    add_csrf (state, csrf) {
      state.csrf = csrf;
    },
    add_user (state, user) {
      state.user = user;
    },
    add_username (state, username){
      state.username = username;
    },
    add_role (state, role){
      state.role = role;
    },
    set_impersonate (state, user) {
      state.impersonate = user.id;
      state.impersonate_role = user.role;
    },
    reset_impersonate (state) {
      state.impersonate = null
      state.impersonate_role = null;
    },
    set_store_version (state, version){
      state.store_version = version;
    }
  },
  actions: {
    async getCsrf (context) {
      try {
        const res = await axios.get(process.env.VUE_APP_AXIOS_BASE+'get-csrf');
        console.log(res['data']);
        context.commit('add_csrf', res['data']['csrf'])
        context.commit('add_user', res['data']['user'])
        context.commit('add_role', res.data.role)
        context.commit('add_username', res.data.username)
        if (!context.state.store_version || context.state.store_version != process.env.VUE_APP_STORE_NUM){
          context.commit('orders/reset_order')
          console.log('New Store Version: '+ process.env.VUE_APP_STORE_NUM)
          context.commit('set_store_version', process.env.VUE_APP_STORE_NUM)
        }
        let site = await axios.get("/site", {'headers': {'X-CSRF': res['data']['csrf']}});
        let cur_season = site.data.data.content.currentseason[0].text;
        console.log(cur_season)
        if (cur_season != context.rootState.orders.order_season) {
          console.log('Season has changed, clearing cart...')
          context.commit('orders/reset_order')
        }
        
      } catch (err) {
        // context.commit('add_csrf', null)
        console.log(err)
      }
    },
    async impersonate (context, name) {
      if (context.state.role == 'admin'){
        let csrf = context.state.csrf;
        let res = await axios.post('impersonate',{'name': name}, {'headers': {'X-CSRF': csrf}});
        let id = res.data.id;
        console.log(id)
        context.commit('set_impersonate', res.data);
        context.commit('orders/change_billing', null, { root: true });
        context.commit('orders/change_shipping', null, { root: true });
        context.dispatch('orders/new_order_details', null, { root: true });
      }
    },
    toggle_role (context) {
      if (context.state.role == 'admin'){
        let new_role
        if (context.state.impersonate_role != 'personal') {
          new_role = 'personal'
        } else {
          new_role = 'wholesale'
        }
        console.log('Toggling role to '+ new_role)
        context.commit('reset_impersonate')
        context.commit('set_impersonate', {'id': null, 'role': new_role})
      }
    },
    async logout (context) {
      let csrf = context.state.csrf;
      let res = await axios.post('auth/logout', {'headers': {'X-CSRF': csrf}});
      console.log(res);
      context.commit('add_csrf', null)
      context.commit('set_impersonate', {id: null, role: null});
      // context.commit('orders/reset_order')
      window.location.href = process.env.VUE_APP_AXIOS_BASE+"login"
    }
  },
}

const emptyOrder = {
  name: null,
  order_id: null,
  order_name: null,
  order_season: null,
  payment_terms: null,
  order_note: null,
  status: 'draft',
  billing_address: null,
  shipping_address: null,
  po_billing_address: null,
  po_shipping_address: null,
  po_payment_id: null,
  po_shipping_cost: null,
  order: {},
}

const orders = {
  namespaced: true,
  state: emptyOrder,
  getters: {
    getTotalStyles(state){
      return Object.keys(state.order).length

    },
    getStyles(state){
      return Object.keys(state.order)
    },
    getOrderTotals(state, getters, rootState, rootGetters){
      let order_totals = {};
      let order_costs = {};
      let total_cost = 0;
      let total_items = 0;
      let empty_styles = false;
      for (const [key,] of Object.entries(state.order)) {
        let style_matrix = state.order[key].items
        let subtotal = style_matrix.reduce((accumulator, value) => {
          return accumulator + value.reduce((prev, next) => {
            return prev + next;
          }, 0)
        }, 0);
        order_totals[key] = subtotal
        total_items += subtotal
        if (subtotal == 0){
          empty_styles = true
        }
        //Check for PO here
        var style_cost
        if (rootGetters.is_po){
          style_cost = state.order[key].wsp17
        } else {
          style_cost = state.order[key].wsp
        }
        order_costs[key] = style_cost * subtotal
        total_cost += order_costs[key] 
      }
      return {'order_totals': order_totals, 'order_costs': order_costs, 'total_items': total_items, 'total_cost': total_cost, 'empty_styles': empty_styles}
    },
    getSizes: (state) => (style_id) => {
      function compare( a, b ) {
        a.barcode = parseInt(a.barcode)
        b.barcode = parseInt(b.barcode)
        if ( a.barcode < b.barcode ){
          return -1;
        }
        if ( a.barcode > b.barcode ){
          return 1;
        }
        return 0;
      }

      if(state.order[style_id]){
        let swatch = state.order[style_id].swatches[0]
        console.log(swatch);
        let items = state.order[style_id].item_lines.filter(item_lines => item_lines.swatch == swatch )

        items.sort(compare)

        let sizes = []
        items.forEach(function(item){
          sizes.push(item.size)
        })
        return sizes
      } else {
        return []
      }
    },
    getSwatches: (state) => (style_id) => {
      if(state.order[style_id]){
        return state.order[style_id].swatches;
      } else {
        return []
      }
    },
    getBarcodeAndID: (state) => (style_id, swatch, size) => {
      let item = state.order[style_id].item_lines.find(item_lines => item_lines.swatch == swatch && item_lines.size == size)
      return {'barcode':item.barcode, 'product_id': item.product_id}
    },
    colourHasPo: (state) => (style_id, swatch) => {
      let items = state.order[style_id].item_lines.filter(item_line => item_line.swatch == swatch && item_line.pop == "")
      return items.length == 0;
    },
    orderLines(state, getters){
      let order_lines = [];
      // Iterate over styles
      for (const [key, order_info] of Object.entries(state.order)) {
        // Whole style matrix:
        let new_line = {style: key, items: []}
        console.log(key);
        let items  = matrix(order_info.items);
        // check if empty
        if (sum(items) > 0) {
          let [rows, cols] = items.size();
          // check if row is empty
          for (let r=0;r<rows;r++) {
            let item_row = row(items, r);
            console.log(sum(item_row));
            if(sum(item_row) > 0) {
              // Finally check and add quantites...
              for (let c=0;c<cols;c++) {
                let quantity = items.get([r,c]);
                if (quantity > 0) {
                  let swatch = order_info.swatches[r]
                  let size = order_info.sizes[c]
                  let lookup = getters.getBarcodeAndID(key, swatch, size)
                  new_line.items.push({
                                    'colour': swatch,
                                    'size': size,
                                    'qty': quantity,
                                    'item': lookup.barcode,
                                    'product_id': lookup.product_id,
                                  })
                }
              }
            }
          }
        }
        order_lines.push(new_line);
      }
      return order_lines;
    },
  },
  mutations: {
    reset_order (state) {
      Object.assign(state, emptyOrder)
      state.order = {}
    },
    add_id (state, id) {
      state.order_id = id;
    },
    add_name (state, name){
      state.order_name = name;
    },
    add_season (state, season) {
      state.order_season = season;
    },
    add_payment_terms(state, payment_terms) {
      state.payment_terms = payment_terms;
    },
    add_order_note(state, note) {
      state.order_note = note;
    },
    mutate_style (state, new_order) {
      if(!Object.prototype.hasOwnProperty.call(state.order, new_order.style_id)) {
        state.order[new_order.style_id] = {'name': new_order.style,
                                          'items': null,
                                          'sizes': new_order.sizes,
                                          'swatches': new_order.swatches,
                                          'item_lines': new_order.item_lines,
                                          'wsp': new_order.wsp,
                                          'wsp17': new_order.wsp17,
                                          };
      }

    },
    add_items (state, items_data){
      let rows = items_data.swatches_len
      let cols = items_data.sizes.length
      let items = Array.from(Array(rows), () => new Array(cols).fill(0));
      // let items = zeros(items_data.swatches_len, items_data.sizes.length)
      state.order[items_data.style_id].items = items
      state.order[items_data.style_id].sizes = items_data.sizes

    },
    change_items (state, new_item) {
      state.order[new_item.style].items = new_item.items;
    },
    change_item_qty(state, item){
      state.order[item.style].items[item.row][item.col] = item.qty;
    },
    append_item_qty(state, item){
      state.order[item.style].items[item.row][item.col] += 1;
    },
    remove_style (state, style_id) {
      delete state.order[style_id];
    },
    change_name (state, new_name) {
      state.name = new_name;
    },
    change_status (state, new_status) {
      state.status = new_status;
    },
    change_billing (state, new_address) {
      console.log("changing billing to: "+ new_address)
      state.billing_address = new_address;
    },
    change_shipping (state, new_address) {
      state.shipping_address = new_address;
    },
    change_po_billing (state, new_address) {
      console.log("changing billing to: "+ new_address)
      state.po_billing_address = new_address;
    },
    change_po_shipping (state, new_address) {
      state.po_shipping_address = new_address;
    },
    change_po_payment_id (state, new_id) {
      state.po_payment_id = new_id;
    },
    change_po_shipping_cost(state, new_shipping){
      state.po_shipping_cost = new_shipping;
    },
  },
  actions: {
    async new_order_details(context) {
      var user = context.rootGetters.site_user
      let csrf = context.rootState.auth.csrf;
      let res = await axios.post('new-order', {'user':user}, {'headers': {'X-CSRF': csrf}});
      context.commit('add_season', res.data.season)
      context.commit('add_payment_terms', res.data.payment_terms)
    },
    async add_style (context, new_order) {
      if(!context.state.order_season) {
        context.dispatch('new_order_details');
      }
      let data = {
        query: "page('items').children.filterBy('style', '"+new_order.style+"')",
        select: {
          swatch: "page.colour",
          size: "page.size",
          barcode: "page.barcode",
          product_id: "page.product_id",
          wsp: "page.wsp",
          pop: "page.wsp17",
        }
      }
      let csrf = context.rootState.auth.csrf;
      let res = await axios.post('query/', data, {'headers': {'X-CSRF': csrf}});
      // console.log(res.data.result);
      new_order['item_lines'] = res.data.result
      context.commit('mutate_style', new_order);
      if (context.state.order[new_order.style_id].items == null){
        if(!new_order.sizes){
          new_order.sizes = context.getters.getSizes(new_order.style_id)
        }
        console.log(new_order.sizes)
        context.commit('add_items', {
          style_id: new_order.style_id,
          swatches_len: new_order.swatches.length,
          sizes: new_order.sizes,
        })
      }

    },
    async save_order (context, order_details) {
      let order_lines = context.getters.orderLines
      console.log(order_lines);
      var customer
      if (context.rootState.auth.impersonate){
        customer = context.rootState.auth.impersonate;
      } else if (context.rootState.auth.user) {
        customer = context.rootState.auth.user;
      }
      let order = {
                  'slug': context.state.order_id,
                  'order_name': order_details.order_name,
                  'customer': customer,
                  'status': context.state.status,
                  'billing_address': context.state.billing_address,
                  'shipping_address': context.state.shipping_address,
                  'po_billing_address': context.state.po_billing_address,
                  'po_shipping_address': context.state.po_shipping_address,
                  'po_payment_id': context.state.po_payment_id,
                  'po_shipping_cost': context.state.po_shipping_cost,
                  'payment_terms': context.state.payment_terms,
                  'order_note': context.state.order_note,
                  'items': order_lines,
                  'style_count': order_details.styles,
                  'item_count': order_details.items,
                  'total_cost': order_details.total_cost,
                  'updated': new Date().getTime(),
                  'season': context.state.order_season,
                  'po': context.rootGetters.is_po,
                  }
      let csrf = context.rootState.auth.csrf;
      let res = await axios.post('place-order', order, {'headers': {'X-CSRF': csrf}});
      // console.log(res.data.id);
      context.commit('add_id', res.data.id)
      context.commit('add_name', order_details.order_name)
      return res;
    },
    async load_order (context, orderNumber){
      await context.commit('reset_order')
      let csrf = context.rootState.auth.csrf;
      let query = {
        query: "page('orders').children.find('"+ orderNumber +"')",
        select: {
          customer: "page.customer.toUser",
          updated: "page.updated",
          status: "page.status",
          order_number: "page.slug",
          order_name: "page.order_name",
          season: "page.season",
          payment_terms: "page.payment_terms",
          billing_address: "page.billing_address.toPage",
          shipping_address: "page.shipping_address.toPage",
          order_note: "page.order_note",
        }
      }
      
      let order = await axios.post("query/", query, {'headers': {'X-CSRF': csrf}});
      console.log(order);
      // Add main order details
      let content = order.data.result;
      context.commit('add_id', content.order_number)
      context.commit('add_name', content.order_name)
      context.commit('add_season', content.season)
      context.commit('add_payment_terms', content.payment_terms)
      context.commit('add_order_note', content.order_note)
      context.commit('change_status', content.status)
      let addresses = {'billing': null, 'shipping':null};
      if(content.billing_address){
        context.commit('change_billing', content.billing_address.slug)
        addresses.billing = content.billing_address;
      }
      if (content.shipping_address){
        context.commit('change_shipping', content.shipping_address.slug)
        addresses.shipping = content.shipping_address;
      }
      // Impersonate user if admin
      if (context.rootState.auth.role == 'admin'){
        context.commit('set_impersonate', {
          'id': content.customer.id,
          'role': content.customer.role.name
        }, { root: true })
      }
      //
      let res = await axios.get("/load-order/"+orderNumber, {'headers': {'X-CSRF': csrf}});
      console.log(res)
      // add styles one by one
      let styles = res.data;
      for(var s=0;s<styles.length;s++){
        let style = styles[s]
        let new_order = {'style_id' :style.style,
                        'style': style.style_name,
                        'swatches':style.swatches,
                        'sizes': style.sizes,
                        'wsp': style.wsp,
                        'wsp17': style.wsp17,
                      };
        await context.dispatch('add_style', new_order);
        // let items = zeros(style.swatches.length, style.sizes.length)
        let items = Array.from(Array(style.swatches.length), () => new Array(style.sizes.length).fill(0));
        for (var i=0;i<style.items.length;i++) {
            let item = style.items[i];
            let row = style.swatches.indexOf(item.colour)
            let col = style.sizes.indexOf(item.size)
            items[row][col] = item.qty
        }
        context.commit('change_items', {'style':style.style, 'items':items})

      }
      return {'customer': content.customer, 'updated': content.updated, 'addresses': addresses} 

    },
    async gen_payment_intent(context) {
      let order_lines = [];
      // Iterate over styles
      for (const [key, order_info] of Object.entries(context.state.order)) {
        // Whole style matrix:
        console.log(key);
        // let item_lines = [];
        let style_qty = 0;
        let items  = matrix(order_info.items);
        // check if empty
        if (sum(items) > 0) {
          let [rows, cols] = items.size();
          // check if row is empty
          for (let r=0;r<rows;r++) {
            let item_row = row(items, r);
            console.log(sum(item_row));
            if(sum(item_row) > 0) {
              // Finally check and add quantites...
              for (let c=0;c<cols;c++) {
                let quantity = items.get([r,c]);
                if (quantity > 0) {
                  // let swatch = order_info.swatches[r]
                  // let size = order_info.sizes[c]
                  // let lookup = context.getters.getBarcodeAndID(key, swatch, size)
                //   item_lines.push({
                //                     'qty': quantity,
                //                     'barcode': lookup.barcode,
                //                   })
                  style_qty += quantity;
                }
                  
              }
            }
          }
        }
        order_lines.push({'style': key, 'qty': style_qty})
      }
      let totals = context.getters.getOrderTotals
      let data = {
        'styles': order_lines, 
        'countrycode': context.state.po_billing_address.countrycode,
        'metadata': totals.order_totals,
      }
      console.log(data)
      let csrf = context.rootState.auth.csrf;
      let res = await axios.post("create-payment", data, {'headers': {'X-CSRF': csrf}});
      context.commit('change_po_shipping_cost', res.data.shipping / 100)
      return(res.data)
    },
  },
}


export default createStore({
  plugins: [vuexLocal.plugin],
  modules: {
    'auth': auth,
    'orders': orders,
    'review': orders
  },
})
