<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"  preserveAspectRatio="xMinYMin meet">
        <path d="M95.5,49.5c0,25.13-20.37,45.5-45.5,45.5S4.5,74.63,4.5,49.5,24.87,4,50,4c8.94,0,17.28,2.58,24.31,7.03" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="5"/>
    </svg>
</template>
<script>
	export default {
		name: 'LoadingElement'
    }
</script>
<style scoped>

    @keyframes spinner {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }

    svg{
        /* tran */
        width: 40px;
        animation: spinner 1s ease-in-out infinite;
    }

    @media print{
        svg{
            display: none;
        }
    }

</style>