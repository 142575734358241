<template>
    <div class="account-container page-container">
        <div class="page-grid">
            <div class="sidebar xs-hide lg-show lg-col-3">
                <div class="sidebar-grid">
                    <div>
                        <h2 class="subhead caps page-header">Admin Panel</h2>
                        <nav class="submenu box order-actions">
                            <ul>
                                <li class="line no-pad">
                                    <router-link to="/admin/orders">Orders</router-link>
                                </li>
                                <li class="line no-pad">
                                    <router-link to="/admin/users">Users</router-link>
                                </li>
                                <li class="line no-pad">
                                    <router-link to="/admin/account-details">My Details</router-link>
                                </li>
                                <li class="line no-pad">
                                    <a href="#" @click="logout">Sign Out </a>
                                </li>
                            </ul>
                        </nav>
                        <!-- <p class="line no-pad">Logged in as: {{ cur_user }}</p> -->
                    </div>
                    
                </div>
            </div>
            <div class="xs-col-12 lg-col-9 account-router-view">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AdminView",
    data() {
        return {
            users: [],
            role: null,
        };
    },
    computed: {
        hasUsers() {
            if (this.$store.state.auth.role) {
                return this.$store.state.auth.role == "admin";
            } else {
                return false;
            }
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("logout");
        },
    },
    async mounted() {
    },
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/variables";

// .submenu{
// 	li{
// 		// border-top: 1px solid var(--lightBorder);
// 	}
// 	a, button{
// 		height: 32px;
// 		line-height: 34px;
// 		display: block;
// 		width: 100%;
// 		&:hover{
// 			// background: var(--lightBorder);
// 			// color: black;
// 		}
// 	}
// }

.switch-section {
    margin: 48px 0;
    // background: var(--lightGreyBG);
    padding: 15px 0;
    max-width: 300px;

    .form-input {
        padding: 0;
    }
}

.submenu {
    margin-bottom: var(--pageRowGap);
    .no-pad {
        padding: 0;
    }

    a,
    button {
        display: block;
        padding: 15px;
        &:hover {
            background-color: var(--lightBorder);
            color: black;
        }
    }

    .router-link-active {
        background: var(--MGYellowGreen);
    }
}

@media only screen and (min-width: nth($md, 2)) {
    .sidebar-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: var(--pageColumnGap);
    }

    .switch-section {
        margin: 0px 0;
        // background: var(--lightGreyBG);
        padding: 0px 0;
        max-width: none;
    }

    .account-router-view {
        padding: var(--pageRowGap) 0 0;
    }
}

@media only screen and (min-width: nth($lg, 2)) {
    .sidebar-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: var(--pageRowGap);
    }

    .account-router-view {
        padding: 0 0;
    }
}
</style>